<template>
    <AppOnePageDropdown
        v-model="value"
        :idValue="idValue"
        optionLabel="descricao"
        placeholder="Selecione a unidade de medida..."
        :service="$service"
        :itemSize="itemSize"
    >
        <template #value="slotProps">
            <div class="flex">
                <span>
                    {{ formatLabel(this.customRender('value', slotProps)) }}
                </span>
                <sup v-if="slotProps.value?.possuiExponenciacao && this.customRender('value', slotProps)?.length < 37">
                    {{ slotProps.value?.valorExponenciacao }}
                </sup>
                <span v-else-if="this.customRender('value', slotProps)?.length >= 37">...</span>
            </div>
        </template>
        <template #option="slotProps">
            <div class="flex">
                <span>
                    {{ this.customRender('option', slotProps) }}
                </span>
                <sup v-if="slotProps.option?.possuiExponenciacao">
                    {{ slotProps.option?.valorExponenciacao }}
                </sup>
            </div>
        </template>
    </AppOnePageDropdown>
</template>
<script>
import SesmtService from '../../../services/SesmtService';

export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new SesmtService('/unidades-medida');
    },
    methods: {
        customRender(key, slotProps) {
            if (!slotProps?.[key]?.simbolo) {
                return slotProps?.[key]?.descricao;
            }

            return `${slotProps?.[key]?.descricao} (${slotProps?.[key]?.simbolo})`;
        },
        formatLabel(text) {
            return text?.substring(0, 37);
        }
    }
};
</script>
